var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"course"},[_c('div',{staticClass:"course_body"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"course-about-page"},[_c('h5',[_vm._v(_vm._s(_vm.$t('About_course')))]),_c('div',{staticClass:"course-details-wrapper",domProps:{"innerHTML":_vm._s(_vm.course?.detail)}})]),_c('router-link',{staticClass:"course-details-next-btn",attrs:{"to":{
          name: 'course-module',
          params: {
            slug: _vm.course?.slug,
            module: _vm.course?.modules[0].id
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t('Further'))+" ")])],1)]),_c('SideMenuNew')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }