<template>
  <div class="course" v-loading.fullscreen.lock="loading">
    <div class="course_body">
      <div class="text-center">
        <div class="course-about-page">
          <h5>{{ $t('About_course') }}</h5>
          <div class="course-details-wrapper" v-html="course?.detail"></div>
        </div>
        <router-link
          :to="{
            name: 'course-module',
            params: {
              slug: course?.slug,
              module: course?.modules[0].id
            }
          }"
          class="course-details-next-btn"
        >
          {{ $t('Further')}}
        </router-link>
      </div>
    </div>

    <SideMenuNew />
  </div>
</template>

<script>
import SideMenuNew from "@/components/course/SideMenuNew";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AboutCourse",
  components: {SideMenuNew},
  mounted() {
    if (!this.course) {
      this.fetchCourse(this.$route.params.slug);
    }
  },
  methods: {
    ...mapActions({
      fetchCourse: 'fetchCourse',
    })
  },
  computed: {
    ...mapGetters([
      'isCourseLoading',
      'getCourse',
    ]),
    loading() {
      return this.isCourseLoading
    },
    course() {
      return this.getCourse
    },
  }
};
</script>

<style lang="scss" scoped>
.course-about-page {
  background: #fff;
  border-radius: 20px;
  padding: 0;
  text-align: center;
}
.course-about-page h5{
  background: #16C95D;
  display: inline-block;
  border-radius: 0 0 20px 20px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding: 5px 40px 10px;
  margin: 0 auto;
}
.course-details-wrapper {
  padding: 32px 40px 32px 50px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0B1F35;
  background-repeat: no-repeat;
  background-size: 280px;
  background-position: right center;
  margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
  .course {
    flex-direction: column-reverse;
    gap: 20px;
    padding-top: 60px;

    &_body {
      width: 100%;
      max-width: 800px;
    }
  }
}
</style>
