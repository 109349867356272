<template>
  <div class="overview">
    <div class="overview_title">
      {{ getCourse?.title }}
    </div>
    <div class="overview_list">
      <div v-for="item in menu">
        <div
          class="overview_item"
          :class="{'active': isCurrentPage(item), 'noaccess': !item.available}"
          @click="toPage(item)"
        >
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="overview_item" v-if="getCourse?.progress === 100">
        <div class="name">
          {{ $t('Course_completed') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SideMenu",
  methods: {
    isCurrentPage(item) {
      if (item.link.name === "course-module") {
        return this.$route.matched.some(({ name }) => name === item.link.name) && Number(this.$route.params.module) === item.link.params.module;
      }
      return this.$route.matched.some(({ name }) => name === item.link.name);
    },
    toPage({link}) {
      this.$router.push(link);
    }
  },
  computed: {
    ...mapGetters([
      'getCourse',
      'getSideMenu'
    ]),
    menu() {
      return this.getSideMenu
    },
    currentModule() {
      return this.getCourse?.modules.find(({ id }) => Number(this.$route.params.module) === id);
    },
    nextModuleAvailable() {
      console.log(this.currentModule);

      const idx = this.getCourse?.modules.indexOf(this.currentModule);
      const nextIndex = (idx + 1) % this.getCourse?.modules.length;
      return this.getCourse?.modules[nextIndex]?.themes[0].available;
    },
  }
}
</script>

<style lang="scss" scoped>
.overview {
  background: #fff;
  width: 400px;
  min-width: 400px;
  border-radius: 20px;
  padding: 0 28px 28px 0;
  margin-left: auto;
  overflow: hidden;

  &_title {
    font-size: 22px;
    font-weight: 600;
    margin: 28px 0 28px 28px;
  }

  &_current {
    background: #fff;
    height: 43px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 16px;
    margin-bottom: 8px;
    display: none;
  }

  .chapter {
    background: #16C95D;
    display: flex;
    gap: 15px;
    align-items: center;
    color: #fff;
    font-weight: 500;
    padding: 10px 28px;
    width: fit-content;
    border-radius: 20px 0;
    margin-bottom: 16px;
    cursor: pointer;
  }

  &_item {
    background: #F6F7F8;
    border-radius: 8px;
    padding: 12px 20px;
    margin-top: 15px;
    margin-left: 28px;
    cursor: pointer;

    .name {
      color: #0B1F35;
    }

    &.active, &:hover {
      background: #007BFC;

      .name {
        color: #fff;
      }
    }

    &.noaccess {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .topic {
    background: #F6F7F8;
    border-radius: 8px;
    padding: 0 20px 16px;
    margin-left: 28px;
    margin-top: 12px;
    max-height: 70px;
    overflow: hidden;
    transition: 0.5s ease;

    &.active {
      max-height: 400px;
      background: #E9F4FF;

      .name {
        color: #007BFC;
        font-weight: 600;
      }

      .topic_header .icon {
        transform: rotate(180deg);

        path {
          stroke: #007BFC;
        }
      }
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      margin-bottom: 16px;
      cursor: pointer;

      .icon {
        transition: 0.5s ease;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      gap: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      cursor: pointer;

      &.active, &:hover {
        background: #007BFC;
        color: #fff;

        svg path {
          // fill: white;
          stroke: white;
        }
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        line-clamp: 1;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .overview {
    background: transparent;
    width: 100%;
    max-width: 800px;
    margin-left: 0;
    padding: 0;
    min-width: auto;

    &_current {
      display: flex;

      .icon {
        transition: 0.5s ease;
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &_title {
      margin-top: 0;
    }

    &_item, .topic {
      margin-left: 0;
    }

    &_list {
      background: #fff;
      padding: 8px 16px;
      border-radius: 10px;
      max-height: 1000px;
      overflow: hidden;

      &.hidden {
        max-height: 0;
        padding: 0 16px;
      }
    }
  }
}
</style>
